var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mt-6 mb-5" },
            [
              _c(
                "v-col",
                { attrs: { lg: "7" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "card-shadow bg-gradient-default border-radius-xl",
                    },
                    [
                      _c("v-img", {
                        staticClass:
                          "position-absolute opacity-2 start-0 top-0 w-100 h-100",
                        attrs: {
                          src: require("@/assets/img/shapes/waves-white.svg"),
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "px-8 pt-6" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "position-relative" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "mt-lg-n16 px-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c("v-img", {
                                    staticClass:
                                      "\n                    w-75\n                    mt-n7 mt-lg-n16\n                    d-none d-md-block\n                    mx-auto\n                    z-index-1\n                  ",
                                    attrs: {
                                      src: require("@/assets/img/tesla-model-s.png"),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { lg: "6" },
                                },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "text-h4 text-white font-weight-bold opacity-9",
                                    },
                                    [_vm._v(" Since Last Charge ")]
                                  ),
                                  _c("hr", {
                                    staticClass: "horizontal light mt-1 mb-4",
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center",
                                    },
                                    [
                                      _c("div", { staticClass: "ms-auto" }, [
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "\n                        mb-0\n                        text-h6\n                        font-weight-normal\n                        text-white\n                        opacity-8\n                      ",
                                          },
                                          [_vm._v(" Distance ")]
                                        ),
                                        _c(
                                          "h3",
                                          {
                                            staticClass:
                                              "text-white text-h3 font-weight-bold",
                                          },
                                          [
                                            _vm._v(" 145 "),
                                            _c(
                                              "small",
                                              { staticClass: "text-sm" },
                                              [_vm._v("Km")]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "ms-lg-16 me-auto" },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "\n                        mb-0\n                        text-h6\n                        font-weight-normal\n                        text-white\n                        opacity-8\n                      ",
                                            },
                                            [_vm._v(" Average Energy ")]
                                          ),
                                          _c(
                                            "h3",
                                            {
                                              staticClass:
                                                "text-white text-h3 font-weight-bold",
                                            },
                                            [
                                              _vm._v(" 300 "),
                                              _c(
                                                "small",
                                                { staticClass: "text-sm" },
                                                [_vm._v("Kw")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { lg: "6" },
                                },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "text-h4 text-white font-weight-bold opacity-9",
                                    },
                                    [_vm._v(" Nearest Charger ")]
                                  ),
                                  _c("hr", {
                                    staticClass: "horizontal light mt-1 mb-4",
                                  }),
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c("div", { staticClass: "ms-auto" }, [
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "text-h6 font-weight-bold mb-0 text-white",
                                        },
                                        [_vm._v(" Miclan, DW ")]
                                      ),
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "text-h6 font-weight-bold mb-0 text-white",
                                        },
                                        [_vm._v(" 891 Limarenda road ")]
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "ms-16 me-auto" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              outlined: "",
                                              rounded: "",
                                              color: "#fff",
                                              width: "36px",
                                              height: "36px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "material-icons-round",
                                                attrs: { size: "16" },
                                              },
                                              [_vm._v("map")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "5", cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "bg-gradient-default border-radius-xl mb-3",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "px-4 py-4" },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "row" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "my-auto",
                                          attrs: { cols: "8" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "numbers" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "\n                          text-white text-sm\n                          mb-0\n                          text-capitalize\n                          font-weight-bold\n                          opacity-7\n                        ",
                                                },
                                                [_vm._v(" Weather today ")]
                                              ),
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "text-white text-h5 font-weight-bolder mb-0",
                                                },
                                                [
                                                  _vm._v(
                                                    " San Francisco - 29°C "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-end",
                                          attrs: { cols: "4" },
                                        },
                                        [
                                          _c("v-img", {
                                            staticClass: "w-50 ms-auto",
                                            attrs: {
                                              src: require("@/assets/img/small-logos/icon-sun-cloud.png"),
                                            },
                                          }),
                                          _c(
                                            "h5",
                                            {
                                              staticClass:
                                                "mb-0 text-white text-h5 text-end me-1",
                                            },
                                            [_vm._v(" Cloudy ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "6", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow border-radius-xl px-4 py-4 mb-lg-0 mb-4",
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-start",
                                      attrs: { sm: "4" },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass:
                                            "shadow border-radius-xl mt-n8",
                                          attrs: {
                                            color: "bg-gradient-default",
                                            height: "64",
                                            width: "64",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "text-white material-icons-round",
                                              attrs: { size: "24" },
                                            },
                                            [_vm._v(" drive_eta ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-end",
                                      attrs: { sm: "8" },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-white text-sm mb-0 text-body",
                                        },
                                        [_vm._v(" Today's Trip ")]
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "text-h4 text-dark font-weight-bolder mb-0",
                                        },
                                        [_vm._v(" 145 Km ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "6", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow border-radius-xl px-4 py-4",
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-start",
                                      attrs: { sm: "4" },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass:
                                            "shadow border-radius-xl mt-n8",
                                          attrs: {
                                            color: "bg-gradient-default",
                                            height: "64",
                                            width: "64",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "text-white material-icons-round",
                                              attrs: { size: "24" },
                                            },
                                            [_vm._v(" battery_full ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-end",
                                      attrs: { sm: "8" },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-white text-sm mb-0 text-body",
                                        },
                                        [_vm._v(" Battery Health ")]
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "text-h4 text-dark font-weight-bolder mb-0",
                                        },
                                        [_vm._v(" 99 % ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "6", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow border-radius-xl px-4 py-4 mb-lg-0 mb-4",
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-start",
                                      attrs: { sm: "4" },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass:
                                            "shadow border-radius-xl mt-n8",
                                          attrs: {
                                            color: "bg-gradient-default",
                                            height: "64",
                                            width: "64",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "text-white material-icons-round",
                                              attrs: { size: "24" },
                                            },
                                            [_vm._v(" speed ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-end",
                                      attrs: { sm: "8" },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-white text-sm mb-0 text-body",
                                        },
                                        [_vm._v(" Average Speed ")]
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "text-h4 text-dark font-weight-bolder mb-0",
                                        },
                                        [_vm._v(" 56 Km/h ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "6", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow border-radius-xl px-4 py-4",
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-start",
                                      attrs: { sm: "4" },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass:
                                            "shadow border-radius-xl mt-n8",
                                          attrs: {
                                            color: "bg-gradient-default",
                                            height: "64",
                                            width: "64",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "text-white material-icons-round",
                                              attrs: { size: "24" },
                                            },
                                            [_vm._v(" volume_up ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-end",
                                      attrs: { sm: "8" },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-white text-sm mb-0 text-body",
                                        },
                                        [_vm._v(" Music Volume ")]
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "text-h4 text-dark font-weight-bolder mb-0",
                                        },
                                        [_vm._v(" 15/100 ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { staticClass: "bg-gradient-default border-radius-xl" },
                    [
                      _c(
                        "div",
                        { staticClass: "px-6 py-6" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { lg: "4", md: "6" } },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: { id: "navbar-search-main" },
                                    },
                                    [
                                      _c(
                                        "v-text-field",
                                        {
                                          staticClass:
                                            "\n                      font-size-input\n                      placeholder-lighter\n                      input-alternative input-icon\n                      text-white\n                    ",
                                          attrs: {
                                            "rounded-sm": "",
                                            "hide-details": "",
                                            outlined: "",
                                            "background-color":
                                              "rgba(255,255,255,0)",
                                            color: "rgb(255,255,255)",
                                            lighter: "",
                                            placeholder: "Search anything...",
                                          },
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "prepend-inner" },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "mt-n1 text-lg material-icons-round",
                                                  attrs: { color: "#fff" },
                                                },
                                                [_vm._v("search")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "my-auto ms-auto",
                                  attrs: { md: "6" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "ms-auto text-lg material-icons-round",
                                                          attrs: {
                                                            color: "#fff",
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_vm._v("headphones")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Headphones connected"),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "text-lg ms-4 material-icons-round",
                                                          attrs: {
                                                            color: "#fff",
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_vm._v("play_arrow")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Music is playing"),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "text-lg ms-4 material-icons-round",
                                                          attrs: {
                                                            color: "#fff",
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "power_settings_new"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [_c("span", [_vm._v("Start radio")])]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "text-lg ms-4 material-icons-round",
                                                          attrs: {
                                                            color: "#fff",
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_vm._v("watch")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [_c("span", [_vm._v("Time tracker")])]
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "text-white text-h4 font-weight-bold ms-6",
                                        },
                                        [_vm._v(" 10:45 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("hr", {
                            staticClass: "horizontal light mt-4 mb-4",
                          }),
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { lg: "4", md: "6" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center position-relative",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "text-h3 font-weight-bolder text-white mb-1",
                                      },
                                      [_vm._v(" 11:13 ")]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-white opacity-8 mb-1 ms-3",
                                      },
                                      [_vm._v(" Estimated arrival time ")]
                                    ),
                                    _c("hr", {
                                      staticClass:
                                        "vertical d-md-block d-none light mt-0 mb-4",
                                    }),
                                  ]
                                ),
                              ]),
                              _c("v-col", { attrs: { lg: "4", md: "6" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center position-relative",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "text-h3 font-weight-bolder text-white mb-1 ms-auto",
                                      },
                                      [
                                        _vm._v(" 2.4"),
                                        _c(
                                          "small",
                                          { staticClass: "align-top text-sm" },
                                          [_vm._v("Km")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-white opacity-8 mb-1 ms-3 me-auto",
                                      },
                                      [_vm._v(" Turn right in 2.4 miles ")]
                                    ),
                                    _c("hr", {
                                      staticClass:
                                        "vertical d-md-block d-none light mt-0",
                                    }),
                                  ]
                                ),
                              ]),
                              _c("v-col", { attrs: { lg: "4", md: "6" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center position-relative",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "text-h3 font-weight-bolder text-white mb-1 ms-auto",
                                      },
                                      [
                                        _vm._v(" 6.3"),
                                        _c(
                                          "small",
                                          { staticClass: "align-top text-sm" },
                                          [_vm._v("Km")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-white opacity-8 mb-1 ms-3 me-auto",
                                      },
                                      [_vm._v(" Distance to Creative Tim ")]
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", [
                        _c(
                          "div",
                          { staticStyle: { height: "270px", width: "100%" } },
                          [
                            _vm.showMap
                              ? _c(
                                  "l-map",
                                  {
                                    attrs: {
                                      zoom: _vm.zoom,
                                      center: _vm.center,
                                      options: _vm.mapOptions,
                                    },
                                    on: {
                                      "update:center": _vm.centerUpdate,
                                      "update:zoom": _vm.zoomUpdate,
                                    },
                                  },
                                  [
                                    _c("l-tile-layer", {
                                      attrs: {
                                        url: _vm.url,
                                        attribution: _vm.attribution,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "px-6 py-6" },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { lg: "4", md: "6" } }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "position-relative" },
                                      [
                                        _c(
                                          "v-avatar",
                                          { attrs: { size: "58" } },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/img/drake.jpeg"),
                                                alt: "Curved",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("img", {
                                          staticClass:
                                            "\n                        position-absolute\n                        w-60\n                        end-0\n                        bottom-0\n                        me-n4\n                        mb-n2\n                      ",
                                          attrs: {
                                            src: require("@/assets/img/small-logos/logo-spotify.svg"),
                                            alt: "spotify logo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "px-3" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-white text-sm font-weight-bold mb-0",
                                        },
                                        [_vm._v(" Scorpion (feat Quavo) ")]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-white text-xs mb-2 opacity-8",
                                        },
                                        [_vm._v(" Drake - Hip-Hop ")]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("v-col", { attrs: { lg: "4", md: "6" } }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ms-auto",
                                        attrs: {
                                          icon: "",
                                          outlined: "",
                                          rounded: "",
                                          color: "#fff",
                                          width: "52px",
                                          height: "52px",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "rotate-180 material-icons-round",
                                            attrs: { size: "19" },
                                          },
                                          [_vm._v("skip_previous")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ms-6",
                                        attrs: {
                                          icon: "",
                                          outlined: "",
                                          rounded: "",
                                          color: "#fff",
                                          width: "52px",
                                          height: "52px",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "material-icons-round",
                                            attrs: { size: "19" },
                                          },
                                          [_vm._v("pause")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ms-6 me-auto",
                                        attrs: {
                                          icon: "",
                                          outlined: "",
                                          rounded: "",
                                          color: "#fff",
                                          width: "52px",
                                          height: "52px",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "material-icons-round",
                                            attrs: { size: "19" },
                                          },
                                          [_vm._v("skip_next")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { lg: "2", md: "6", cols: "8" } },
                                [
                                  _c("p", { staticClass: "text-white mb-0" }, [
                                    _vm._v("Volume"),
                                  ]),
                                  _c("v-slider", {
                                    staticClass: "progress-shadow mb-0",
                                    attrs: {
                                      color: "bg-primary",
                                      "track-color": "#e9ecef",
                                      "thumb-label": "",
                                      max: "100",
                                    },
                                    model: {
                                      value: _vm.slider,
                                      callback: function ($$v) {
                                        _vm.slider = $$v
                                      },
                                      expression: "slider",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "ms-auto",
                                  attrs: { lg: "1", md: "6", cols: "4" },
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "ms-auto mt-4 material-icons-round",
                                                      attrs: { color: "#fff" },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("format_list_bulleted")]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [_c("span", [_vm._v("Hide menu")])]
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "mt-4 ms-4 material-icons-round",
                                                      attrs: { color: "#fff" },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("mode_comment")]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [_c("span", [_vm._v("Track messages")])]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }